class GroupTravelForm {
	constructor($, $el, utils) {
		const hideIconFromReader = helpTextDiv => {
			helpTextDiv.setAttribute('aria-hidden', helpTextDiv.textContent.trim().length === 0);
		};

		const addAriaRole = helpTextDiv => {
			helpTextDiv.setAttribute('role', 'alert');
		};

		const updateDatepickerAccessibility = datePicker => {
			datePicker.setAttribute('aria-label', datePicker.name === 'arrival-date' ? 'Arrival Date' : 'Departure Date');
		};

		const updateCountryAccessibility = countrySelectEl => {
			countrySelectEl.setAttribute('aria-label', 'Country');
		};

		const associateInputWithHelpBlock = () => {
			const $inputs = $el.find('input, textarea, select');
			$inputs.each((_, input) => {
				const $helpBlock = $(input).closest('.form-group').find('.help-block.ng-scope');
				if ($helpBlock.length && !$helpBlock.attr('id')) {
					const helpBlockId = 'helpBlock' + Math.random().toString(36).substr(2, 9);
					$helpBlock.attr('id', helpBlockId);
					$(input).attr('aria-describedby', helpBlockId);
				}
			});
		};

		const applyAccessibilityUpdates = element => {
			console.log('~~~ GroupTravelForm Module ~~~');

			const $element = $(element);
			const $helpIconDivs = $element.find('.help-block.ng-binding:not(.ng-scope):not(.ng-hide)');
			const $helpBlocks = $element.find('.help-block');
			const $arrivalDatePicker = $element.find('#arrival-date');
			const $departureDatePicker = $element.find('#departure-date');
			const $countrySelect = $element.find('select[name="address.country"]');
			const $statusMessage = $element.find('[ng-bind-html="vm.currentForm.afterSubmissionText"]');

			if ($statusMessage.length) {
				$statusMessage.attr({
					'role': 'status',
					'aria-live': 'polite'
				});
			}

			$helpIconDivs.each((_, div) => hideIconFromReader(div));
			$helpBlocks.each((_, div) => addAriaRole(div));

			if ($arrivalDatePicker.length) {
				updateDatepickerAccessibility($arrivalDatePicker[0]);
			}
			if ($departureDatePicker.length) {
				updateDatepickerAccessibility($departureDatePicker[0]);
			}
			if ($countrySelect.length) {
				updateCountryAccessibility($countrySelect[0]);
			}

			associateInputWithHelpBlock();
		};

		const initializeFormObserver = () => {
			const observer = new MutationObserver(mutations => {
				mutations.forEach(mutation => {
					if (mutation.type === 'childList') {
						mutation.addedNodes.forEach(node => {
							if (node.nodeType === Node.ELEMENT_NODE) {
								applyAccessibilityUpdates(node);
							}
						});
					} else if (mutation.type === 'characterData' || mutation.type === 'attributes') {
						applyAccessibilityUpdates(mutation.target);
					}
				});
			});

			const config = { childList: true, subtree: true, characterData: true };
			observer.observe($el[0], config);
			applyAccessibilityUpdates($el[0]);
		};

		this.name = () => 'GroupTravelForm';

		this.init = () => initializeFormObserver();
	}
}

export default GroupTravelForm;

