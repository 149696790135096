import flatpickr from 'flatpickr';
import moment from 'moment';
import CustomEvent from 'custom-event';
import Loader from './Loader';


class ReservationFlyout {
	/**
	 * ReservationFlyout Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - ReservationFlyout instance
	 * @param {object} utils - Utils instance
	 */
	constructor($, $el, utils) {

		let qs = new URLSearchParams(window.location.search);
		let initialFormParams;

		const params = {
			locale: $el.data('local') || 'en',
			searchCacheExpiration: $el.data('property-search-cache-expiration'),
			maxResults: $el.data('property-search-max-results'),
			maxResultsMobile: $el.data('property-search-max-results-mobile'),
			minQuery: $el.data('property-search-min-query'),
			searchUrl: $el.data('property-search-url'),
			browseUrl: $el.data('property-browse-url'),
			rateUrl: $el.data('property-rate-url'),
			roomRateUrl: $el.data('room-type-rate-url'),
			// codeValidationUrl: $el.data('code-validation-url'),
			propertySynxisId: $el.data('property-synxis-id'),
			propertyDisplayName: $el.data('property-display-name'),
			propertyRoomCode: $el.data('property-room-code'),
			propertyRoomDisplayName: $el.data('property-room-display-name'),
			currency: $el.data('currency') || '$',
			startDate: qs.get('start-date'),
			endDate: qs.get('end-date'),
			template: $el.data('template') || 'aap_jp_rbe',
			shell: $el.data('shell') || 'aap_jp_rbe',
		};
		const objects = {
			propertySearch: {
				resultData: {},
				$btnClose: $el.find('[data-action="close"]'),
				$btnDone: $el.find('[data-action="done"]'),
				$btnSearch: $el.find('[data-action="search"]'),
				$btnBack: $el.find('[data-action="back"]'),
				$noResults: $el.find('#mar-search-no-results'),
				$searchResultsContainer: $el.find('#mar-search-results-container'),
				$searchResults: $el.find('#mar-search-results'),
				$search: $el.find('#mar-search'),
				$searchDest: $el.find('[data-destination]'),
				$searchHotel: $el.find('[name="hotel"]'),
				$propertyListOptions: $el.find('.list-options button'),
				$propertyDataList: $el.find('#mar-destination-list'),
				$specialRateList: $el.find('#rate-dropdown'),
				$specialRateInput: $el.find('#special-rate-input'),
				$specialRateFilter: $el.find('[name="filter"]'),
			},
			$reservationModal: $('#ReservationFlyoutModal'),
			pendingSearchRequest: false,
			pendingBrowseRequest: false,
			pendingRateRequest: false,
			pendingCodeValidationRequest: false,
			rateData: {},
			unavailableDates: [],
			$form: $el.find('form'),
			$btnConfirmDates: $el.find('[data-action="confirm-dates"]'),
			$calendar: $el.find('.calendar')[0],
			$fp: {},
			$marActiveMonthSR: $el.find('#mar-active-month-sr'),
			$marDateDisplay: $el.find('#mar-date'),
			$marStartDate: $el.find('#mar-start-date'),
			$marEndDate: $el.find('#mar-end-date'),
			$marRoomDisplay: $el.find('[data-room-count-display]'),
			$marAdultDisplay: $el.find('[data-adult-count-display]'),
			$marChildDisplay: $el.find('[data-child-count-display]'),
			$marRoomCount: $el.find('#mar-room-count'),
			$marAdultCount: $el.find('#mar-adult-count'),
			$marChildCount: $el.find('#mar-child-count'),
			$promoCode: $el.find('#promo-code'),
			$groupCode: $el.find('#group-code'),
			$marPromoCode: $el.find('#mar-promo-code'),
			$marGroupCode: $el.find('#mar-group-code'),
			$adjusters: $el.find('[data-adjust]'),
			$numbers: $el.find('input[type="number"]'),
			$roomsGuestsBtn: $el.find('#btn-rooms-guests'),
			$marDisplayDateRange: $el.find('#mar-display-date-range'),
			$marSRDateRange: $el.find('#mar-sr-date-range'),
			$marDisplayPropertyName: $el.find('#mar-display-property-name'),
			$flightsIframeHotelName: $('#hotel-flight-modal .hotel-name'),
			$marDisplayRoomName: $el.find('#mar-display-room-name'),
			$submit: $el.find('[data-action="submit"]'),
			$flightBtn: $el.find('.js-show-flight-modal'),
			$close: $el.find('[data-action="close"]'),

			$unavailableDialog: $el.find('.dates-unavailable-container'),
			$unavailableClose: $el.find('.dates-unavailable-dialog .btn-close'),
			$unavailableMsgContainer: $el.find('.unavailable-msg'),
			$unavailableActions: $el.find('.dates-unavailable-actions'),
			$unavailableHotelName: $el.find('#unavailable-hotel-name'),

			$requiredMsgs: $el.find('.dates-unavailable-msg'),
			$unavailablePropertyMessages: $el.find('[data-unavailable="property"]'),
			$unavailableRoomMessages: $el.find('[data-unavailable="room"]'),
			$closedMsg: $el.find('.js-avail-closed-msg'),
			$minStayMsg: $el.find('.js-avail-minstay-msg'),
			$daysInAdvMsg: $el.find('.js-days-in-advance-msg'),
			$minStayHelpMsg: $el.find('.js-avail-minstay-help'),
			lastSearch: '',
			MLOSnotMet: false,
			$flightModaliFrame: $(document).find('iframe.hotel-flight'),
			$flightRestriction: $el.find('.js-flight-restriction'),
			$loader: $el.find('[data-loader]'),
			objLoader: null,
			dialogDismissed: false,

			$collapses: $el.find('.collapse'),
			$calendarCollapse: $el.find('#calendar'),
		};

		let searchCacheInterval = 0;
		const searchCacheExpiration = params.searchCacheExpiration || 5000; //client caching interval in milliseconds
		const minQuery = params.minQuery || 2;

		//* Added for WCAG compliance
		//* per https://projects.avantia.net/issues/24958
		objects.$reservationModal.on('show.bs.modal', () => {
			objects.$reservationModal.removeClass('d-none');
		});

		objects.$reservationModal.on('hide.bs.modal', () => {
			objects.$reservationModal.addClass('d-none');
		});
		//*

		/*this.clearSearchCache = () => {
			objects.propertySearch.resultData = {};
		};*/

		/** ********** Start: Property Search ********** **/

		/**
		 * performPropertySearch()
		 * Searches Properties using keyword
		 * @param {string} query -  Keyword value to search
		 */
		/*this.performPropertySearch = (query) => {
			if(objects.lastSearch === query) {
				return;
			}
			objects.lastSearch = query;

			// reset cache interval
			if (searchCacheInterval) {
				clearInterval(searchCacheInterval);
			}

			let results = objects.propertySearch.resultData;

			// check for value already existing in cache
			if (query.length >= minQuery && !(query in results)) {
				// query not in cache - need to fetch
				let client = params.searchUrl.replace(/{query}/g, query);
				$.ajax({
					cache: false,
					type: 'GET',
					url: client,
					beforeSend: function (xhr) {
						if (xhr && xhr.overrideMimeType) {
							xhr.overrideMimeType('application/json;charset=utf-8');
						}
					},
					dataType: 'json'
				})
					.done((res) => {
						const results = objects.propertySearch.resultData;
						results[query] = res.data;
						this.renderSearchResults(query);
					})
					.fail((err) => {
						//console.error('Error searching for property: ', err);
					})
					.always(() => {
					});

			} else if (query in results) {
				// query already performed with data in cache
				this.renderSearchResults(query);
			} else {
				objects.propertySearch.$browse.show();
				objects.propertySearch.$searchResults.empty();
				objects.propertySearch.$searchResultsContainer.hide();
				objects.propertySearch.$searchResults.hide();
				objects.propertySearch.$noResults.hide();
				objects.propertySearch.$browse.show();
				if (query.length < minQuery) {
					objects.propertySearch.$btnClose.hide();
				}
			}
		};*/

		this.setBookUrl = (startDate, endDate) => {
			let $btnBook = $('[data-action="book"]');
			let promoCode = objects.$promoCode.val();
			let groupCode = objects.$groupCode.val();

			$btnBook.each((i, e) => {
				let bookUrl = $(e).attr('href');

				bookUrl = bookUrl
					.replace(/{arrive}/g, moment(startDate).format('MM-DD-YYYY'))
					.replace(/{depart}/g, moment(endDate).format('MM-DD-YYYY'))
					.replace(/{promo}/g, promoCode)
					.replace(/{group}/g, groupCode)
					.replace(/{adult}/g, objects.$marAdultCount.val())
					.replace(/{child}/g, objects.$marChildCount.val());

				$(e).attr('href', bookUrl);
			});
		};

		this.handleSearchInputChange = (e) => {
			if (e.key !== 'Tab' && e.key !== 'Shift') {
				const query = $(e.currentTarget).val().toLowerCase();

				let resultCount = 0;
				for (let property of objects.propertySearch.$searchResultsContainer[0].children) {
					if (property.innerText.toLowerCase().indexOf(query) > -1) {
						resultCount++;
						$(property).show();
					} else {
						$(property).hide();
					}
				}

				if (resultCount === 0) {
					$(objects.propertySearch.$searchResultsContainer[0].children).show();
				}

				if (query.length > 0) {
					objects.propertySearch.$searchResultsContainer.collapse('show');
				}
			}

			if ($(e.currentTarget).val() === '') {
				this.clearSelectedProperty();
				this.handlePropertyChange(e);
			}
		};

		/** ********** End: Property Search ********** **/

		this.changeProperty = (displayName) => {
			objects.$marDisplayPropertyName.text(displayName);
			objects.$flightsIframeHotelName.text(displayName);
			objects.$marDisplayPropertyName.show();

			objects.propertySearch.$search.focus();

			// trigger month change to get data
			this.handleLocalStorage();
			// removed rate fetching on property change: this.handleCalendarMonthChange();
			this.validateForm();
		};

		this.handleOnReady = () => {
			// flatpickr ADA fixes
			let $monthSelect = $('select.flatpickr-monthDropdown-months');
			let $yearInput = $('input.cur-year');
			objects.$marActiveMonthSR.text(`${$monthSelect.find('option:selected').text()} ${$yearInput.val()}`);
			$monthSelect.attr('aria-label', 'select month');
			$yearInput.attr('aria-label', 'select year');
			$yearInput.removeAttr('tabindex');

			$('.flatpickr-calendar').attr('role', 'application');
			$('.flatpickr-prev-month, .flatpickr-next-month').attr('role', 'button').attr('tabindex', 0);

			$('.flatpickr-weekdaycontainer').attr('aria-hidden', 'true');
			$('.flatpickr-prev-month').attr('aria-label', 'previous month');
			$('.flatpickr-next-month').attr('aria-label', 'next month');

			$('.flatpickr-prev-month, .flatpickr-next-month').on('keydown', (e) => {
				if (e.key === 'Enter') {
					if ($(e.target).hasClass('flatpickr-prev-month')) {
						objects.$fp.changeMonth(-1);
					} else if ($(e.target).hasClass('flatpickr-next-month')) {
						objects.$fp.changeMonth(1);
					}
				}
			});

			const $dialog = $el.find('.flatpickr-calendar').parent();

			let focusableEls = [
				$('.flatpickr-prev-month'),
				$('.flatpickr-monthDropdown-months'),
				$('.flatpickr-next-month'),
				($('.flatpickr-day.selected') || $('.flatpickr-day[tabindex=0]')[0]),
				$('button[data-action="done"]'),
			];

			objects.$reservationModal.on('keydown', (e) => {

				if(!$dialog.hasClass('show')) {

					let $activeElement = $(document.activeElement);

					switch (e.key) {
						case 'Tab': {
							if (e.shiftKey) {
								if ($activeElement.hasClass('close')) {
									e.preventDefault();
									$el.find('.support-number').focus();
								}
							} else if ($activeElement.hasClass('support-number')) {
								e.preventDefault();
								$el.find('.close').focus();
							}
							break;
						}
					}
				}
			});

			$dialog.on('keydown', (e) => {
				let $activeElement = $(document.activeElement);

				switch (e.key) {
					case 'Tab': {
						if (e.shiftKey) {
							if ($activeElement.hasClass('flatpickr-day')) {
								$(focusableEls[2]).focus();
							} else if ($activeElement.hasClass('flatpickr-prev-month')) {
								e.preventDefault();
								$('button[data-action="done"]').focus();
							} else if ($activeElement.hasClass('flatpickr-monthDropdown-months') &&  $el.find('.flatpickr-prev-month').hasClass('flatpickr-disabled')) {
								e.preventDefault();
								$(focusableEls[focusableEls.length - 1]).focus();
							}
						} else if ($activeElement.attr('data-action') === 'done') {
							e.preventDefault();
							if (!$el.find('.flatpickr-prev-month').hasClass('flatpickr-disabled')) {
								$el.find('.flatpickr-prev-month').focus();
							} else {
								$el.find('.flatpickr-monthDropdown-months').focus();
							}
						}
						break;
					}

					case 'Escape': {
						e.stopPropagation();
						objects.$calendarCollapse.collapse('hide');
						break;
					}
					case 'ArrowUp': {
						break;
					}
					case 'ArrowDown': {
						break;
					}
					case 'ArrowRight': {
						break;
					}
					case 'ArrowLeft': {
						break;
					}
					case 'PageUp': {
						if (e.shiftKey) {
							if (
								objects.$fp.currentYear === moment().year() + 1 && objects.$fp.currentMonth >= moment().month()
								|| (objects.$fp.currentYear > moment().year() + 1)
							) {
								objects.$fp.changeMonth(-12);
							} else {
								// jump to first available (current) date
								objects.$fp.jumpToDate(moment().toDate(), true);
							}
						} else {
							objects.$fp.changeMonth(-1);
						}

						this.fixCalendarDayTabIndex(true);
						break;
					}
					case 'PageDown': {
						if (e.shiftKey) {
							objects.$fp.changeMonth(12);
						} else {
							objects.$fp.changeMonth(1);
						}

						this.fixCalendarDayTabIndex(true);
						break;
					}
					case 'Home': {
						if ($activeElement.hasClass('flatpickr-day')) {
							e.preventDefault();
							// move to start of week
							const dataDate = moment($activeElement.attr('data-date'));
							const startOfWeek = dataDate.clone().startOf('week');
							const startOfMonth = dataDate.clone().startOf('month');
							let targetDate = (startOfWeek.month() < dataDate.month()) ? startOfMonth : startOfWeek;
							$(`.flatpickr-day[data-date="${targetDate.format('YYYY-MM-DD')}"]`).focus();
						}
						break;
					}
					case 'End': {
						if ($activeElement.hasClass('flatpickr-day')) {
							e.preventDefault();
							// move to end of week
							const dataDate = moment($activeElement.attr('data-date'));
							const endOfWeek = dataDate.clone().endOf('week');
							const endOfMonth = dataDate.clone().endOf('month');
							let targetDate = (endOfWeek.month() > dataDate.month()) ? endOfMonth : endOfWeek;
							$(`.flatpickr-day[data-date="${targetDate.format('YYYY-MM-DD')}"]`).focus();
						}
						break;
					}
				}
			});
		};

		this.fixCalendarMonthTabIndex = () => {
			setTimeout(() => {
				// hack to 'correct' flatpickr setting of tabindex in 'buildMonthSwitch()'
				$el.find('.flatpickr-monthDropdown-months, .flatpickr-monthDropdown-month').removeAttr('tabindex');
			}, 250);
		};

		this.fixCalendarDayTabIndex = (shouldFocus = true) => {
			setTimeout(() => {
				let $anchor = $el.find('.flatpickr-day.startRange');

				if (
					$anchor.length > 0
					&& $anchor.is(':visible')
					&& !$anchor.hasClass('nextMonthDay')
					&& !$anchor.hasClass('prevMonthDay')
				) {
					$anchor.attr('tabindex', 0);

					if (shouldFocus) {
						$anchor.focus();
					}
				} else {
					$anchor = $el.find('.flatpickr-day:not(.disabled)').first();
					$anchor.attr('tabindex', 0);

					if (shouldFocus) {
						$anchor.focus();
					}
				}
			}, 100);
		};

		/**
		 * handlePropertyChange()
		 * Handles change of Property selection made from Search or Browse
		 * @param {event} e -  Event
		 */
		this.handlePropertyChange = (e) => {
			//remove hidden input used for pre-selecting Property
			// objects.$form.find('input[type="hidden"][name="hotel"]').remove();
			objects.$form.find('input[type="hidden"][name="room"]').remove();

			let $selectedOption = $(e.currentTarget);
			let displayName = $selectedOption.data('display-name') || $selectedOption.data('value');
			let hotel = $selectedOption.data('id') || $selectedOption.data('property-id');
			let destination = $selectedOption.data('destination') || $selectedOption.data('destination-id');

			let bookingUrl = $selectedOption.data('booking-url');
			let alwaysAvailable = $selectedOption.data('always-available');
			let plusFlightDisabled = $selectedOption.data('flight-disabled');

			objects.$form.attr('data-flight-disabled', plusFlightDisabled);
			objects.$form.attr('data-always-available', alwaysAvailable);

			// disable and clear Hotel and Destination inputs
			objects.propertySearch.$searchDest.prop('disabled', true).val('');
			objects.propertySearch.$searchHotel.prop('disabled', true).val('');

			// set corresponding input values based on hotel/destination selection
			if (hotel) {
				objects.propertySearch.$searchHotel.prop('disabled', false).val(hotel);
			} else if (destination) {
				objects.propertySearch.$searchDest.prop('disabled', false).val(destination);
			}

			if (plusFlightDisabled) {
				objects.$flightBtn.attr('aria-disabled', true);
				objects.$flightBtn.attr('focusable', false).attr('tabindex', -1);
			}

			// remove any previous hidden inputs created/set from query string
			objects.$form.find('[data-booking-url-param]').remove();

			if (bookingUrl) {
				// create/update hidden inputs from query string parameters
				const url = new URL(bookingUrl);
				const urlParams = new URLSearchParams(url.search);

				for (const [key, value] of urlParams.entries()) {
					if (value) {
						const input = objects.$form.find(`input[name="${key}"]`);
						if (input.length > 0) {
							input[0].val(value);
						} else {
							$('<input type="hidden">').attr({
								name: key,
								value,
								'data-booking-url-param': true,
							}).appendTo(objects.$form);
						}
					}
				}

				// set form action to url without parameters
				objects.$form.attr('action', url.origin + url.pathname);
			} else {
				// no overriding booking url - revert to initial form parameters
				initialFormParams.appendTo(objects.$form);
			}

			objects.propertySearch.$propertyListOptions.removeClass('selected');
			$selectedOption.addClass('selected');

			this.changeProperty(displayName);
		};

		/**
		 * dateRangeToArray()
		 * Creates array of dates for a given date range in provided date format
		 * @param {date} startDate
		 * @param {date} endDate
		 * @param {string} format
		 * @returns {array} Array of date values
		 */
		this.dateRangeToArray = (startDate, endDate, format = 'YYYY-MM-DD') => {
			let dateArray = [];
			let iDate = startDate.startOf('day');
			while (iDate <= endDate.startOf('day')) {
				dateArray.push(iDate.format(format));
				iDate = iDate.add(1, 'days');
			}
			return dateArray;
		};

		/**
		 * getRateData()
		 * Gets Property rate data for provided date range and property id
		 * @param {date} startDate
		 * @param {date} endDate
		 * @param {string} propertyId
		 */
		this.getRateData = (startDate, endDate, propertyId) => {
			let client;
			let roomCode = $el.find('input[type="hidden"][name="room"]');
			let promoCode = objects.$marPromoCode.val();
			let specialRateCodes = objects.propertySearch.$specialRateFilter.data('codes');
			let groupCode = objects.$marGroupCode.val();

			if (objects.pendingRateRequest) {
				return false;
			}

			objects.pendingRateRequest = true;
			let requiredKeys = [this.dateRangeToArray(moment(startDate), moment(endDate)), groupCode];

			if (propertyId) {

				if (objects.rateData[propertyId] && requiredKeys.every(key => Object.hasOwnProperty.call(objects.rateData[propertyId], key))) {
					objects.pendingRateRequest = false;
				} else {
					// query not in cache - need to fetch
					if (roomCode.length > 0) {
						client = params.roomRateUrl
							.replace(/{roomType}/g, roomCode.val());
					} else {
						client = params.rateUrl;
					}

					client = client
						.replace(/{propertyId}/g, propertyId)
						.replace(/{startDate}/g, moment(startDate).format('YYYY-MM-DD'))
						.replace(/{endDate}/g, moment(endDate).format('YYYY-MM-DD'));

					if (promoCode) {
						client += `&promoCodes=${promoCode}`;
					}

					if (specialRateCodes) {
						client += `&specialRates=${specialRateCodes}`;
					}

					if (groupCode) {
						client += `&groupCodes=${groupCode}`;
					}

					objects.objLoader.startLoader();
					$('.flatpickr-calendar').attr('aria-busy', true);

					$.ajax({
						cache: false,
						type: 'GET',
						url: client,
						dataType: 'json'
					})
						.done((res) => {
							let rates = res.data.rates;
							// let message = res.data.message;

							objects.rateData[propertyId] = Object.assign({}, objects.rateData[propertyId], rates);
							this.setPickerDates(objects.$fp.selectedDates, false);
						})
						.fail((err) => {
							//console.log('Error getting rate data: ', err);
						})
						.always(() => {
							objects.pendingRateRequest = false;
							objects.$fp.redraw();

							this.fixCalendarMonthTabIndex();
							this.fixCalendarDayTabIndex(false);

							objects.objLoader.stopLoader();
							$('.flatpickr-calendar').attr('aria-busy', false);
						});
				}
			} else {
				objects.pendingRateRequest = false;
			}
		};

		this.handleCalendarDayCreate = (dObj, dStr, fp, dayElem) => {
			let selectedPropertyId = this.getSelectedPropertyId();
			let currentDate = moment();
			let ariaLabel = dayElem.getAttribute('aria-label');

			dayElem.setAttribute('data-date', moment(dayElem.dateObj).format('YYYY-MM-DD'));

			if (moment(dayElem.dateObj) >= currentDate.subtract(1, 'days')) {

				let dayDate = moment(dayElem.dateObj).format('YYYY-MM-DD');

				if (selectedPropertyId && objects.rateData[selectedPropertyId]) {
					let propertyRateData = objects.rateData[selectedPropertyId];
					let dayRateData = propertyRateData[dayDate];
					let alwaysAvailable = objects.$form.attr('data-always-available') === 'true';

					if (alwaysAvailable) {
						dayElem.setAttribute('data-minstay', -1);
						dayElem.setAttribute('aria-label', ariaLabel + ' Continue to see rates.');
						dayElem.setAttribute('tabindex', 0);
						dayElem.setAttribute('role', 'button');
					}
					else if (dayRateData) {
						let rate = dayRateData.rate;
						if(rate && (!isNaN(rate))) {
							let ariaLabel = dayElem.getAttribute('aria-label');
							// let thisRate = `${params.currency}${Math.round(rate)}`;

							if(dayRateData.availability === 'MinStay') {
								dayElem.className += ' min-stay';
								dayElem.setAttribute('data-minstay', dayRateData.avail_qty);
								dayElem.setAttribute('aria-label', ariaLabel + ' This day requires a minimum stay of ' + dayRateData.avail_qty + ' days.' /* This day has a rate of ' + thisRate */);
							} else {
								dayElem.setAttribute('data-minstay', -1);
								// dayElem.setAttribute('aria-label', ariaLabel + ' This day has a rate of ' + thisRate);
							}
							// dayElem.innerHTML += `<div class="rate">${params.currency}${Math.round(rate)}</div>`;
						} else {
							// dayElem.innerHTML += '<div class="slash">&nbsp;</div>';
							dayElem.className += ' unavailable';
							dayElem.setAttribute('aria-label', ariaLabel + ' is unavailable');
						}
					} else {
						// dayElem.innerHTML += '<div class="slash">&nbsp;</div>';
						dayElem.className += ' unavailable';
						dayElem.setAttribute('aria-label', ariaLabel + ' is unavailable');
					}
				}
			} else {
				// dayElem.setAttribute('tabindex', -1);
				dayElem.className += ' disabled';
				dayElem.disabled = true;
			}

			// flatpickr ADA fixes
			$(dayElem).attr('role', 'button');

			if (moment(dayElem.dateObj).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
				$(dayElem).attr('aria-current', 'date');
			}

			if ($(dayElem).hasClass('prevMonthDay') || $(dayElem).hasClass('nextMonthDay')) {
				$(dayElem).addClass('disabled');
				$(dayElem).attr('aria-hidden', 'true');
				$(dayElem).removeAttr('aria-label');
			}

			if ($(dayElem).hasClass('startRange') && $(dayElem).hasClass('endRange')) {
				dayElem.setAttribute('aria-label', ariaLabel + ' selected as date');
			} else if ($(dayElem).hasClass('startRange')) {
				dayElem.setAttribute('aria-label', ariaLabel + ' selected as check in date');
			} else if ($(dayElem).hasClass('endRange')) {
				dayElem.setAttribute('aria-label', ariaLabel + ' selected as check out date');
			}
		};

		this.setMsgToggleState = (requiredMsg) => {
			objects.$requiredMsgs.attr('tabindex', '-1');
			requiredMsg.attr('tabindex', 0);

			this.initTrapFocus();
		};

		this.setPickerDates = (selectedDates, proceed) => {
			if(undefined === proceed) {
				proceed = true;
			}

			const currentDate = moment();
			const selectedStartDate = moment(selectedDates[0]).startOf('day');
			const selectedEndDate = moment(selectedDates[1]).startOf('day');

			// hide the mobile 'next' button. call to show further down if both dates selected.
			objects.$btnConfirmDates.hide();

			if (selectedStartDate !== selectedEndDate
				&& selectedStartDate < selectedEndDate
				&& selectedStartDate > currentDate.subtract(1, 'days')
			) {
				objects.$marDisplayDateRange.val(`${selectedStartDate.format('MMM DD, YYYY')} - ${selectedEndDate.format('MMM DD, YYYY')}`);
				// objects.$marDisplayDateRange.attr('aria-label', `${selectedStartDate.format('MMM DD, YYYY')} - ${selectedEndDate.format('MMM DD, YYYY')}`);
				//objects.$marSRDateRange.text(`selected date range ${selectedStartDate.format('MMM DD, YYYY')} - ${selectedEndDate.format('MMM DD, YYYY')}`);
				objects.$marStartDate.val(selectedStartDate.format('MM-DD-YYYY'));
				objects.$marEndDate.val(selectedEndDate.format('MM-DD-YYYY'));

				// Save to local storage
				localStorage.setItem('startDate', selectedStartDate.format('YYYY-MM-DD'));
				localStorage.setItem('endDate', selectedEndDate.format('YYYY-MM-DD'));

				let selectedPropertyId = this.getSelectedPropertyId();
				let propertyRateData = objects.rateData[selectedPropertyId];

				objects.MLOSnotMet = false;
				objects.unavailableDates = [];
				let minstay = 0;
				let daysInAdv = 0;

				let alwaysAvailable = objects.$form.attr('data-always-available') === 'true';

				if (propertyRateData && !alwaysAvailable) {
					for (let m = moment(selectedStartDate); m.isBefore(selectedEndDate); m.add(1, 'days')) {
						let rate = propertyRateData[m.format('YYYY-MM-DD')];
						if (!rate) {
							objects.unavailableDates.push(m.toDate());
						} else if(rate.availability === 'MinStay') {
							if(minstay < rate.avail_qty) {
								minstay = rate.avail_qty;
							}
						} else if(rate.availability === 'Close' && daysInAdv < rate.avail_qty) {
							daysInAdv = rate.avail_qty;
							objects.unavailableDates.push(m.toDate());
						} else if(rate.availability !== 'Open') {
							objects.unavailableDates.push(m.toDate());
						}
					}
				}

				let datesOk = false;

				let nights = selectedEndDate.diff(selectedStartDate, 'days');

				// not used with Reservation Modal
				objects.$closedMsg.hide();

				// if we have unavailable dates redraw the calendar to get the slashes.
				if (objects.unavailableDates.length > 0) {
					if(daysInAdv > 0) {
						// Days in Advance not met
						objects.$daysInAdvMsg.html(objects.$daysInAdvMsg.attr('data-msg').replace('{0}', daysInAdv));
						objects.$daysInAdvMsg.show();

						this.setMsgToggleState(objects.$daysInAdvMsg);

						objects.$unavailableRoomMessages.hide();
						objects.$unavailablePropertyMessages.hide();
						objects.$minStayHelpMsg.show();
					} else {
						// Closed
						objects.$daysInAdvMsg.hide();
						if (params.propertyRoomCode != null && params.propertyRoomCode.length > 0) {
							objects.$unavailableRoomMessages.show();
						} else {
							objects.$unavailableRoomMessages.hide();
						}

						if (params.propertyRoomCode == null || params.propertyRoomCode.length === 0) {
							objects.$unavailablePropertyMessages.show();
						} else {
							objects.$unavailablePropertyMessages.hide();
						}

						objects.$minStayHelpMsg.hide();
					}

					objects.$minStayMsg.hide();
					// objects.$fp.selectedDates = [];
					objects.$fp.redraw();
					if(!objects.dialogDismissed) {
						let hotelName = objects.propertySearch.$search.val();
						if (!hotelName) {
							hotelName = "The selected destination";
						}
						objects.$unavailableHotelName.text(hotelName);

						objects.$unavailableDialog.show();
						objects.$unavailableActions.show();
						setTimeout(() => {
							objects.$unavailableClose.focus();
						}, 500);
					}
					this.initTrapFocus();

				} else if (minstay > 0 && Number(minstay) > nights) {
					// MLOS
					objects.MLOSnotMet = true;
					objects.$daysInAdvMsg.hide();
					objects.$unavailableRoomMessages.hide();
					objects.$unavailablePropertyMessages.hide();
					objects.$minStayMsg.html(objects.$minStayMsg.attr('data-msg').replace('{0}', minstay));
					objects.$minStayMsg.show();

					this.setMsgToggleState(objects.$minStayMsg);

					objects.$minStayHelpMsg.show();
					// objects.$fp.selectedDates = [];
					objects.$fp.redraw();

					if(!objects.dialogDismissed) {
						objects.$unavailableDialog.show();
						objects.$unavailableActions.show();
						setTimeout(() => {
							objects.$unavailableClose.focus();
						}, 500);

					}
					this.initTrapFocus();
				} else {
					datesOk = true;

					objects.$unavailableDialog.hide();
					objects.$unavailableActions.hide();
					// auto proceed to next step if desktop. mobile users must click 'next' button
					if (utils.getViewportSize() !== 'xs' && utils.getViewportSize() !== 'sm' && !objects.pendingRateRequest && proceed) {
						this.handleDatesConfirmed();
					} else {
						// 'next' button
						objects.$btnConfirmDates.show();
					}
				}

				this.validateForm();
				this.setBookUrl(selectedStartDate, selectedEndDate);

				// Update Book Now Buttons
				$(document)[0].dispatchEvent(new CustomEvent('date-occupancy-update'));
			}
		};

		this.handleCalendarValueUpdate = () => {
			objects.dialogDismissed = false;
			this.setPickerDates(objects.$fp.selectedDates);

			if (objects.$fp.selectedDates[0] && objects.$fp.selectedDates[1]) { // Don't send event for partial date.
				const ev = new CustomEvent('reservationdatesselected', {
					'detail': {
						source: $el,
						startDate: objects.$fp.selectedDates[0],
						endDate: objects.$fp.selectedDates[1]
					}
				});
				$(document)[0].dispatchEvent(ev);
			}
		};

		this.handleCalendarYearChange = () => {
			this.fixCalendarMonthTabIndex();
			this.fixCalendarDayTabIndex(false);
		};

		this.handleCalendarMonthChange = (e) => {
			let selectedPropertyId = this.getSelectedPropertyId();
			let visibleMonth = moment([objects.$fp.currentYear, objects.$fp.currentMonth]);

			let startDate = moment(visibleMonth).subtract(1, 'months');
			let endDate = moment(visibleMonth).add(3, 'months').subtract(1, 'days');

			let alwaysAvailable = objects.$form.attr('data-always-available') === 'true';

			if (selectedPropertyId && !alwaysAvailable) {
				this.getRateData(startDate, endDate, selectedPropertyId);
			} else {
				this.fixCalendarDayTabIndex(false);
				this.fixCalendarMonthTabIndex();
			}

			objects.$marActiveMonthSR.text(`${moment().month(objects.$fp.currentMonth).format('MMMM')} ${objects.$fp.currentYear}`);
		};

		// called automatically after selecting dates on desktop or when 'next' button clicked after selecting dates on mobile.
		this.handleDatesConfirmed = () => {
			objects.dialogDismissed = false;
		};

		this.handleUnavailableClose = () => {
			objects.dialogDismissed = true;
			objects.$unavailableDialog.hide();
			// objects.$marDisplayDateRange.focus();
			this.fixCalendarDayTabIndex(true);
		};

		this.handleLocalStorage = () => {
			let startDateString = localStorage.getItem('startDate');
			let localStorageStartDate = moment(startDateString);
			let endDateString = localStorage.getItem('endDate');
			let localStorageEndDate = moment(endDateString);
			let today = moment().startOf('day').toDate();
			let localStorageAdults = localStorage.getItem('adults');
			let localStorageChildren = localStorage.getItem('children');

			if(localStorageAdults !== null) {
				objects.$marAdultCount.val(localStorageAdults);
			}
			if(localStorageChildren !== null) {
				objects.$marChildCount.val(localStorageChildren);
			}

			// Check date from parameters
			let paramStartDate = moment(params.startDate);
			let paramEndDate = moment(params.endDate);
			if (paramStartDate.isValid() && paramEndDate.isValid()) {
				let startDate = paramStartDate.toDate();
				let endDate = paramEndDate.toDate();

				if (startDate >= today) {
					objects.$fp.jumpToDate(startDate);
					objects.$fp.setDate([startDate, endDate], false);
					this.setPickerDates([startDate, endDate]);
					this.setBookUrl(startDate, endDate);
					return true;
				}
			}

			// Check date from local storage
			if (localStorageStartDate.isValid() && localStorageEndDate.isValid()) {
				let startDate = localStorageStartDate.toDate();
				let endDate = localStorageEndDate.toDate();

				if (startDate >= today) {
					objects.$fp.jumpToDate(startDate);
					objects.$fp.setDate([startDate, endDate], false);
					this.setPickerDates([startDate, endDate]);
					//this.setBookUrl(startDate, endDate);
					return true;
				} else {
					localStorage.removeItem('startDate');
					localStorage.removeItem('endDate');
				}
			} else {
				// No local storage values - use defaults
				let startDate = moment().startOf('day').add(7,'days').toDate();
				let endDate = moment().startOf('day').add(11,'days').toDate();
				objects.$fp.jumpToDate(startDate);
				objects.$fp.setDate([startDate, endDate], false);
				this.setPickerDates([startDate, endDate]);
				this.setBookUrl(startDate, endDate);
				return true;
			}

			return false;
		};


		this.handleNumberChange = (e) => {
			e.preventDefault();
			let $target = $(e.target);
			let amt = 0;

			// change target if event originates from adjuster buttons
			if ($(e.currentTarget).attr('type') === 'button') {
				$target = $(e.currentTarget).siblings('[type="number"]');
				amt = $(e.currentTarget).data('adjust');
			}

			let curVal = parseInt($target.val());
			let min = parseInt($target.attr('min'));
			let max = parseInt($target.attr('max'));

			if (
				(curVal + amt) >= min &&
				(curVal + amt) <= max
			) {
				let newVal = curVal + amt;
				let ariaLabel = $('label[for="rooms-guests-toggle"]').text() + ": ";
				$target.val(newVal);
				$target.attr('aria-valuenow', newVal);

				if ($target.attr('id') === 'mar-adult-count') {
					localStorage.setItem('adults', newVal.toString());
				}
				if ($target.attr('id') === 'mar-child-count') {
					localStorage.setItem('children', newVal.toString());
				}
				if ($target.attr('id') === 'mar-room-count') {
					localStorage.setItem('rooms', newVal.toString());

					if (newVal === 1 && objects.$form.attr('data-flight-disabled') === 'false') {
						objects.$flightBtn.attr('aria-disabled', false);
						objects.$flightBtn.attr('focusable', true).attr('tabindex', 0);
						objects.$flightRestriction.hide();
					} else {
						objects.$flightBtn.attr('aria-disabled', true);
						objects.$flightBtn.attr('focusable', false).attr('tabindex', -1);
						if(objects.$form.attr('data-flight-disabled') === 'false') {
							objects.$flightRestriction.show();
						}
					}
				}

				// Update Rooms & Guests field
				objects.$marRoomDisplay.html(objects.$marRoomCount.val());
				objects.$marAdultDisplay.html(objects.$marAdultCount.val());
				objects.$marChildDisplay.html(objects.$marChildCount.val());

				if (objects.$marRoomCount.val() > 1 || objects.$marRoomCount.val() <= 0) {
					$el.find('[data-room-plural]').show();
					$el.find('[data-room-singular]').hide();

					ariaLabel += objects.$marRoomCount.val() + " " + $el.find('[data-room-plural]').text() + " ";
				} else {
					$el.find('[data-room-plural]').hide();
					$el.find('[data-room-singular]').show();

					ariaLabel += objects.$marRoomCount.val() + " " + $el.find('[data-room-singular]').text() + " ";
				}

				if (objects.$marAdultCount.val() > 1 || objects.$marAdultCount.val() <= 0) {
					$el.find('[data-adult-plural]').show();
					$el.find('[data-adult-singular]').hide();

					ariaLabel += objects.$marAdultCount.val() + " " + $el.find('[data-adult-plural]').text() + " ";
				} else {
					$el.find('[data-adult-plural]').hide();
					$el.find('[data-adult-singular]').show();

					ariaLabel += objects.$marAdultCount.val() + " " + $el.find('[data-adult-singular]').text() + " ";
				}

				if (objects.$marChildCount.val() > 1 || objects.$marChildCount.val() <= 0) {
					$el.find('[data-child-plural]').show();
					$el.find('[data-child-singular]').hide();

					ariaLabel += objects.$marChildCount.val() + " " + $el.find('[data-child-plural]').text();
				} else {
					$el.find('[data-child-plural]').hide();
					$el.find('[data-child-singular]').show();

					ariaLabel += objects.$marChildCount.val() + " " + $el.find('[data-child-singular]').text();
				}

				// disable the clicked adjuster if at min or max.
				$target.attr('disabled', newVal === min || newVal === max);

				$('label[for="rooms-guests-toggle"]').attr("aria-label", ariaLabel);

				// Update Book Now buttons
				$(document)[0].dispatchEvent(new CustomEvent('date-occupancy-update'));
			}
		};

		this.handleSpecialRateSelection = () => {
			let specialRateLabels = [];
			let filterArray = [];
			let codeArray = [];

			objects.propertySearch.$specialRateList.find('input:checked').map(function() {
				specialRateLabels.push($(this).data('title'));
				filterArray.push($(this).val());
				codeArray.push($(this).data('code'));
			});

			if (specialRateLabels.length === 0) {
				objects.propertySearch.$specialRateInput.text(objects.propertySearch.$specialRateInput.data('placeholder'));
			} else if (specialRateLabels.length === 1) {
				objects.propertySearch.$specialRateInput.text(specialRateLabels[0]);
			} else {
				const label = objects.propertySearch.$specialRateInput.attr('data-label-multiple');
				if (label) {
					objects.propertySearch.$specialRateInput.text(label.replace('{0}', specialRateLabels.length));
				} else {
					objects.propertySearch.$specialRateInput.text(specialRateLabels.join(', '));
				}
			}

			objects.propertySearch.$specialRateFilter.val(filterArray);
			objects.propertySearch.$specialRateFilter.data('codes', codeArray);

			// clear Promo/Groups when Special Rate is selected
			objects.$marPromoCode.val('');
			objects.$marGroupCode.val('');

			this.validateForm();
		};

		this.clearSelectedProperty = () => {
			objects.propertySearch.$searchDest.val('');
			objects.propertySearch.$searchHotel.val('');
			objects.propertySearch.$search.val('');
		};

		this.clearSpecialRates = () => {
			for (let specialRate of objects.propertySearch.$specialRateList[0].children) {
				$(specialRate).find('input').prop('checked', false);
			}
			objects.propertySearch.$specialRateInput.text(objects.propertySearch.$specialRateInput.data('placeholder'));
			objects.propertySearch.$specialRateFilter.val('');
		};

		this.getSelectedPropertyId = () => {
			return objects.propertySearch.$searchHotel.val();
		};

		this.openHotelFlightModal = () => {
			let baseUrl = objects.$flightModaliFrame.data('src');

			//https://stg-ovs-gadget.tour-list.com/DPSearch/?ExtSystemCode=DBS&ExtHotelCode=YX-27632&StyleCode=aquaastondialog&Language=en

			// Create URL with base params
			let modalUrl = baseUrl + '?ExtSystemCode=DBS&StyleCode=aquaastondialog&Language=ja';

			// Add hotel
			modalUrl += '&ExtHotelCode=YX-' + this.getSelectedPropertyId();

			// Add start and end dates
			let startDateString = localStorage.getItem('startDate');
			let localStorageStartDate = moment(startDateString);
			let endDateString = localStorage.getItem('endDate');
			let localStorageEndDate = moment(endDateString);
			let formattedStart = moment(localStorageStartDate).format('YYYY/MM/DD');
			let formattedEnd = moment(localStorageEndDate).format('YYYY/MM/DD');
			modalUrl += '&GoDepDate=' + formattedStart;
			modalUrl += '&RtnDepDate=' + formattedEnd;
			modalUrl += '&CheckInDate=' + formattedStart;
			modalUrl += '&CheckOutDate=' + formattedEnd;

			// Add adults
			modalUrl += '&Adult=' + objects.$marAdultCount.val();

			// Add children
			for(let c=0; c < objects.$marChildCount.val(); c++) {
				modalUrl += '&Children=-1';
			}

			objects.$flightModaliFrame.attr('src', modalUrl);
			$('#hotel-flight-modal').modal('show');
			$el.toggleClass('active');
			$('.bodywrapper').toggleClass('active');
			this.toggleFocusOffBody();
		};

		this.handleDatalistUpdate = (currentFocus) => {
			let datalist = objects.propertySearch.$searchResultsContainer[0];

			for (var i = 0; i < datalist.length; i++) {
				datalist[i].classList.remove('active');
			}
			if (currentFocus >= datalist.length) currentFocus = 0;
			if (currentFocus < 0) currentFocus = (datalist.length - 1);
			datalist[currentFocus].classList.add('active');
		};

		this.validateForm = () => {
			let valid = true;

			if (!objects.$marStartDate.val() || !objects.$marEndDate.val()) {
				valid = false;
			} else if (objects.unavailableDates.length) {
				valid = false;
			} else if (objects.MLOSnotMet) {
				valid = false;
			} else if (
				// user unable to get rates with both Special Rate AND Promo/Group Codes
				objects.propertySearch.$specialRateFilter.val().length > 0
				&& (objects.$marPromoCode.val().length > 0 || objects.$marGroupCode.val().length > 0)
			) {
				valid = false;
			}

			let tabIndex = valid ? '0' : '-1';

			objects.$submit.attr('aria-disabled', !valid).attr('tabindex', tabIndex);
			objects.$flightBtn.attr('aria-disabled', !valid).attr('tabindex', tabIndex);
			if (objects.$form.attr('data-flight-disabled') === 'true') {
				objects.$flightBtn.attr('aria-disabled', true).attr('tabindex', '-1');
			}
		};

		this.initTrapFocus = () => {
			setTimeout(function(){
				$el.find('.dates-unavailable-dialog').focus();
			}, 500);

			this.trapFocus($el.find('.dates-unavailable-dialog'), $el.find('.unavailable-msg'));
		};

		this.trapFocus = (element, lastElement) => {
			let focusableEls = element[0].querySelectorAll('input:not([tabindex="-1"]), select:not([tabindex="-1"]), button:not([tabindex="-1"]), a:not([tabindex="-1"]), div[tabindex="0"]');

			let lastFocusableEl = focusableEls[focusableEls.length - 1];

			element[0].addEventListener('keydown', function(e) {

				let isTabPressed = (e.key === 'Tab' || e.keyCode === 9);

				if (!isTabPressed) {
					return;
				}

				if (e.shiftKey) /* shift + tab */ {
					if (document.activeElement === element[0]) {
						lastFocusableEl.focus();
						e.preventDefault();
					}
				} else /* tab */ {

					if (
						(lastElement && document.activeElement === lastElement[0])
						|| document.activeElement === focusableEls[focusableEls.length - 1]
					) {
						element.focus();
						e.preventDefault();
					}
				}

			});
		};

		this.rescueFocus = () => {
			$el.attr('tabindex', '-1');
			$el.attr('aria-hidden', 'true');
			$('[data-toggle="reservation-flyout"]').attr('aria-hidden', 'true');
			$('[data-toggle="reservation-flyout"]').attr('aria-expanded', 'false');
		};

		this.toggleFocusOffBody = () => {
			let ariaAssistant = $('[data-aria-assistant-flyout]');
			ariaAssistant.empty();

			if($('.bodywrapper').hasClass('active')) {
				$('.bodywrapper').attr('tabindex', '-1');
				$('.bodywrapper').attr('focusable', false);
				this.trapFocus($el, $('[data-tel]'));
				ariaAssistant.append(ariaAssistant.data('expanded'));
			} else {
				$('.bodywrapper').attr('tabindex', '0');
				$('.bodywrapper').attr('focusable', true);
				this.rescueFocus();
				ariaAssistant.append(ariaAssistant.data('collapsed'));
			}
		};

		this.handlePromoGroupChange = (e) => {

			if (objects.$marPromoCode.val().length > 0 || objects.$marGroupCode.val().length > 0) {
				this.clearSpecialRates();
				objects.propertySearch.$specialRateInput.prop('disabled', true);
				objects.propertySearch.$specialRateInput.text('');
			} else {
				objects.propertySearch.$specialRateInput.prop('disabled', false);
				objects.propertySearch.$specialRateInput.text(objects.propertySearch.$specialRateInput.data('placeholder'));
			}

			this.validateForm();
		};

		this.firstRun = () => {
			console.info('~~~ ReservationFlyout Module ~~~');

			objects.objLoader = new Loader($, objects.$loader);

			objects.propertySearch.$searchResults.hide();
			objects.propertySearch.$btnClose.hide();

			for (let child of objects.propertySearch.$searchResultsContainer[0].children) {
				child.addEventListener('click', (e) => {
					const $result = $(e.currentTarget);
					objects.propertySearch.$search.val($result.data('value'));

					// initially clear property and destination values
					objects.propertySearch.$searchHotel.val('');
					objects.propertySearch.$searchDest.val('');

					if ($result.data('property-id')) {
						objects.propertySearch.$searchHotel.val($result.data('property-id'));
					} else if ($result.data('destination-id')) {
						objects.propertySearch.$searchDest.val($result.data('destination-id'));
					}

					objects.propertySearch.$searchResultsContainer.collapse('hide');
					this.handlePropertyChange(e);

					this.validateForm();
				});
			}

			objects.propertySearch.$search.keypress((e) => {
				if (e.which === 13) { // Check for the enter key
					e.preventDefault(); // Stop Safari and IE from triggering the button to be clicked
					objects.propertySearch.$searchResultsContainer.collapse('toggle');
				}
			});
			objects.propertySearch.$search.on('keyup', utils.debounce(this.handleSearchInputChange, 500, false));

			for (let specialRate of objects.propertySearch.$specialRateList[0].children) {
				specialRate.addEventListener('click', this.handleSpecialRateSelection);
			}

			objects.$marPromoCode.on('keyup', utils.debounce(this.handlePromoGroupChange, 250, false));
			objects.$marGroupCode.on('keyup', utils.debounce(this.handlePromoGroupChange, 250, false));

			objects.$adjusters.on('click', this.handleNumberChange);
			objects.$numbers.on('change', this.handleNumberChange);
			objects.$btnConfirmDates.on('click', this.handleDatesConfirmed);
			objects.$unavailableClose.on('click', this.handleUnavailableClose);
			objects.$flightBtn.on('click', this.openHotelFlightModal);

			// initialize calendar
			objects.$fp = flatpickr(objects.$calendar, {
				mode: 'range',
				ariaDateFormat: 'l, F J Y', // https://flatpickr.js.org/formatting/
				onDayCreate: this.handleCalendarDayCreate,
				onMonthChange: this.handleCalendarMonthChange,
				onYearChange: this.handleCalendarYearChange,
				onValueUpdate: this.handleCalendarValueUpdate,
				onReady: this.handleOnReady,
				onChange: () => {
					this.fixCalendarMonthTabIndex();
					this.fixCalendarDayTabIndex(false);
				},

				altInput: true,
				altFormat: 'D, M j',
				minDate: moment().format('YYYY-MM-DD'),
				inline: true,
			});
			flatpickr.localize(flatpickr.l10ns[params.locale]);

			objects.$marRoomCount.on('keyup', this.validateForm);
			this.validateForm();

			objects.$btnConfirmDates.hide();
			objects.$unavailableDialog.hide();
			objects.$unavailableActions.hide();

			// check for pre-selected Property and add hidden input
			if (params.propertySynxisId && params.propertyDisplayName) {
				objects.propertySearch.$searchHotel.attr('data-display-name', params.propertyDisplayName);
				objects.propertySearch.$searchHotel.val(params.propertySynxisId);
				objects.propertySearch.$search.val(params.propertyDisplayName);

				// check for pre-selected Room type and add hidden input
				if (params.propertyRoomCode && params.propertyRoomDisplayName) {
					objects.$marDisplayRoomName.text(params.propertyRoomDisplayName);
					objects.$marDisplayRoomName.show();

					let $input = $('<input>').attr('type', 'hidden');
					$input.attr('name', 'room');
					$input.attr('data-display-name', params.propertyRoomDisplayName);
					$input.val(params.propertyRoomCode);
					objects.$form.append($input);
				} else {
					objects.$marDisplayRoomName.hide();
				}

				this.changeProperty(params.propertyDisplayName);
			}

			this.handleLocalStorage();

			let ariaLabel = $('label[for="rooms-guests-toggle"]').text() + ": ";

			// Update Rooms & Guests field
			objects.$marRoomDisplay.html(objects.$marRoomCount.val());
			objects.$marAdultDisplay.html(objects.$marAdultCount.val());
			objects.$marChildDisplay.html(objects.$marChildCount.val());
			if (objects.$marRoomCount.val() > 1 || objects.$marRoomCount.val() <= 0) {
				$el.find('[data-room-plural]').show();
				$el.find('[data-room-singular]').hide();

				ariaLabel += objects.$marRoomCount.val() + " " + $el.find('[data-room-plural]').text() + " ";
			} else {
				$el.find('[data-room-plural]').hide();
				$el.find('[data-room-singular]').show();

				ariaLabel += objects.$marRoomCount.val() + " " + $el.find('[data-room-singular]').text() + " ";
			}

			if (objects.$marAdultCount.val() > 1 || objects.$marAdultCount.val() <= 0) {
				$el.find('[data-adult-plural]').show();
				$el.find('[data-adult-singular]').hide();

				ariaLabel += objects.$marAdultCount.val() + " " + $el.find('[data-adult-plural]').text() + " ";
			} else {
				$el.find('[data-adult-plural]').hide();
				$el.find('[data-adult-singular]').show();

				ariaLabel += objects.$marAdultCount.val() + " " + $el.find('[data-adult-singular]').text() + " ";
			}

			if (objects.$marChildCount.val() > 1 || objects.$marChildCount.val() <= 0) {
				$el.find('[data-child-plural]').show();
				$el.find('[data-child-singular]').hide();

				ariaLabel += objects.$marChildCount.val() + " " + $el.find('[data-child-plural]').text();
			} else {
				$el.find('[data-child-plural]').hide();
				$el.find('[data-child-singular]').show();

				ariaLabel += objects.$marChildCount.val() + " " + $el.find('[data-child-singular]').text();
			}

			$('label[for="rooms-guests-toggle"]').attr("aria-label", ariaLabel);

			$(document).on('reservationdatesselected', (ev) => {
				let detail = ev.detail; // Don't process own events.
				if (objects.$fp && (detail.source !== $el) && typeof objects.$fp.jumpToDate === 'function') {
					objects.$fp.jumpToDate(detail.startDate);
					objects.$fp.setDate([detail.startDate, detail.endDate], false);
					this.setPickerDates([detail.startDate, detail.endDate]);
				}
			});

			objects.$marDisplayDateRange.on('keydown', (e) => {
				if (e.key === 'Enter' || (e.key === ' ' || e.code === 'Space')) {
					e.preventDefault();
					objects.$calendarCollapse.collapse('toggle');
				} else if (e.key === 'Tab') {
					// no-op
				} else {
					return false;
				}
			});

			objects.$calendarCollapse.on('shown.bs.collapse', () => {
				this.handleCalendarMonthChange();
				this.fixCalendarDayTabIndex(true);
			});

			objects.$calendarCollapse.on('hidden.bs.collapse', () => {
				objects.$marDisplayDateRange.focus();
			});

			objects.$reservationModal.on('click', (e) => {
				if (
					!($(e.target).attr('data-toggle') === 'collapse')
					&& !$(e.target).closest('.reservation-dropdown').length > 0
					&& !$(e.target).closest('.dates-unavailable-dialog').length > 0
				) {
					objects.$collapses.each((i, collapse) => {
						if ($(collapse).hasClass('show')) {
							$(collapse).collapse('hide');
						}
					});
				}
			});

			objects.propertySearch.$btnDone.on('click', (e) => {
				const $parentCollapse = $(e.target).closest('.collapse');
				$parentCollapse.collapse('hide');
			});

			$el.find('.collapsed[data-toggle="collapse"], input').on('focus', (e) => {
				const dataTarget = $(e.target).attr('data-target');
				if (
					!$(e.target).closest('.reservation-dropdown').length > 0
				) {
					objects.$collapses.each((i, collapse) => {
						if (
							$(collapse).hasClass('show')
							&& dataTarget !== `#${collapse.id}`
						) {
							$(collapse).collapse('hide');
						}
					});
				}
			});
		};

		window.marfo = this;
		window.marfo.objects = objects;

		window.addEventListener('pageshow', () => {
			// added to address back button retention issue
			if (!(params.propertySynxisId && params.propertyDisplayName)) {
				// clear Property/Destination selection
				this.clearSelectedProperty();
			}
			// clear Special Rates selection
			this.clearSpecialRates();
		});

		initialFormParams = objects.$form.find('[data-booking-url-param]');
	}

	// noinspection JSMethodCanBeStatic
	name() {
		return 'ReservationFlyout';
	}

	init() {
		this.firstRun();
	}

}

export default ReservationFlyout;
