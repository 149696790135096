import objectFitImages from 'object-fit-images';
import RateApi from './utilities/RateApi';
// import Plyr from 'plyr';

class Main {

	constructor($, imports, Utils) {
		// this._imports = imports;

		// /* put all available modules in an object */
		// let _objects = this._imports.reduce( (obj, prop) => {
		// 	console.log(`import: ${prop.name}`);
		// 	obj[prop.name] = prop;
		// 	return obj;
		// }, Object.create(null));

		let _objects = imports;

		/**
		 * injectIcons()
		 * Loads icon bundle file via http request for page injection
		 */
		this.injectIcons = () => {
			const xhr = new XMLHttpRequest();
			let $bundle = $('#icon-bundle');
			let $path = $bundle.data('path') || '../img';
			xhr.onload = (res) => {
				$bundle.html(res.currentTarget.responseText);
			};
			xhr.open('get', `${$path}/icons.svg`, true);
			//xhr.setRequestHeader('Cache-Control', 'max-age=1000');
			xhr.send();
		};

		// /**
		//  * customizeFormFactory()
		//  * Adjusts markup to style Form Factory components
		//  */
		// this.customizeFormFactory = (data, formInfo) => {
		// 	const $el = $('.form-factory');
		// 	const objects = {
		// 		$select: $el.find('select'),
		// 		$datepicker: $el.find('.input-group-btn')
		// 	};
		//
		// 	if ($el.length > 0) {
		//
		// 		// Select Boxes
		// 		objects.$select.each((i, el) => {
		// 			let $selectBox = $(el);
		//
		// 			$selectBox.wrap('<label class="custom-select-label"></label>');
		// 			$selectBox.addClass('custom-select');
		//
		// 			let newLabel = $selectBox.parent();
		// 			newLabel.append('<span class="icon-svg"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-arrow-down"></use></svg></span>');
		// 		});
		//
		// 		// Date Picker
		// 		objects.$datepicker.each((i, el) => {
		// 			let $calendarBtn = $(el).find('button');
		//
		// 			$calendarBtn.append('<span class="icon-svg"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-calendar"></use></svg></span>');
		// 		});
		// 	}
		// };

		/**
		 * instantiateModules()
		 * USAGE: Main.instantiateModules( $(' element to search for modules to instantiate ') )
		 */
		this.instantiateModules = (element = $('body')) => {

			let _utils = new Utils();
			//TODO initialize rateApi module
			let _rateApi = new RateApi();

			element.find('[data-module]').each((i,v) => {
				let name = $(v).data('module');

				if (_objects[name]) {
					const tStart = performance.now();
					let module = new _objects[name]($, $(v), _utils, _rateApi);

					if ($(v).data('init') !== false) {
						module.init();
					}
					_utils.listeners(module);
					const tEnd = performance.now();
					console.debug(`Init took ${tEnd - tStart} ms.`);
				}
			});

			_utils.initViewMore();
			_utils.initScrollTo();
			_utils.initSetSticky();
			_utils.moveModals();
			_utils.trimBadges();

			$(window).ready(function() {
				_utils.trimBadges();
			});

			$(document.body).on('touchmove', function() {
				_utils.setSticky();
			}); // for mobile
		};

		this.detectTabbing = () => {
			function handleFirstTab(e) {
				if (e.keyCode === 9) {
					document.body.classList.add('user-is-tabbing');
					window.removeEventListener('keydown', handleFirstTab);
				}
			}

			window.addEventListener('keydown', handleFirstTab);
		};

		// this.initVideoPlyr = () => {
		// 	const $videos = $('.video-plyr');
		// 	if ($videos.length) {
		// 		import('plyr').then(
		// 			Plyr => Plyr.setup($videos)
		// 		);
		// 	}
		// };

		// According to this ticket, this functionality appears to be not needed any more: https://projects.avantia.net/issues/25238
		/*this.unfocusSvg = () => {
			$(window).on('load', function() {
				setTimeout(function(){
					$('svg').attr('focusable', false).attr('aria-hidden', true);
					$('.icon-svg').attr('tabindex', '-1').attr('aria-hidden', true);
					$('.icon-svg img').attr('tabindex', '-1').attr('aria-hidden', true);
				}, 500);
			});
		};*/
		
	}

	init() {
		this.instantiateModules();
		this.injectIcons();
		objectFitImages();
		this.detectTabbing();
		// this.initVideoPlyr();
		this.unfocusSvg();
	}
}

export default Main;
