// These imports have to be in the correct order for vendor.js to be stand-alone.
import $ from 'jquery';
import 'jquery.cookie';
import 'tether';
import 'bootstrap';
import './modules/Slick';

import Main from './Main';

// Utilities, Polyfills, Etc...
import Utils from './utilities/Utils';

// Modules
import ActivationEmail from './modules/ActivationEmail';
import BookNowButton from './modules/BookNowButton';
import BiographyGroup from './modules/BiographyGroup';
import CalendarFlyout from './modules/CalendarFlyout';
import Carousel from './modules/Carousel';
import Collapsible from './modules/Collapsible';
import DedicatedGallery from './modules/DedicatedGallery';
import DiscountDetailInfo from './modules/DiscountDetailInfo';
import FAQ from './modules/FAQ';
import FavoriteButton from './modules/FavoriteButton';
import Feature from './modules/Feature';
import Filter from './modules/Filter';
import GroupTravelForm from './modules/GroupTravelForms';
import GalleryTabs from './modules/GalleryTabs';
import Header from './modules/Header';
import HotelSelector from './modules/HotelSelector';
import ItineraryList from './modules/ItineraryList';
import Loader from './modules/Loader';
import Login from './modules/Login';
import MapView from './modules/MapView';
import Modal from './modules/Modal';
import NavTabs from './modules/NavTabs';
import OfferSummary from './modules/OfferSummary';
import OfferPropertySearch from './modules/OfferPropertySearch';
import PageHelpful from './modules/PageHelpful';
import ParametricSearch from './modules/ParametricSearch';
import PasswordChange from './modules/PasswordChange';
import PasswordReset from './modules/PasswordReset';
import PersonalGuidance from './modules/PersonalGuidance';
import PropertyDetail from './modules/PropertyDetail';
import PressLanding from './modules/PressLanding';
import PropertyGrid from './modules/PropertyGrid';
import PropertyToggle from './modules/PropertyToggle';
import ReservationFlyout from './modules/ReservationFlyout';
import RoomDetail from './modules/RoomDetail';
import Search from './modules/Search';
import SearchResults from './modules/SearchResults';
import SignUp from './modules/SignUp';
import Stays from './modules/Stays';
import Tablist from './modules/Tablist';
import TravelLogDetails from './modules/TravelLogDetails';
import TravelLogFlyout from './modules/TravelLogFlyout';
import UserProfile from './modules/UserProfile';
import VacationsInProgress from './modules/VacationsInProgress';
import VacationInspiration from './modules/VacationInspiration';
import OfferList from './modules/OfferList';
import WeatherBadge from './modules/WeatherBadge';

console.info('~~~ 🌴 Aloha 🌴 ~~~');

let imports = {
	ActivationEmail,
	BiographyGroup,
	BookNowButton,
	CalendarFlyout,
	Carousel,
	Collapsible,
	DedicatedGallery,
	DiscountDetailInfo,
	FAQ,
	// FavoriteButton,
	Feature,
	Filter,
	GroupTravelForm,
	Header,
	HotelSelector,
	ItineraryList,
	Loader,
	Login,
	MapView,
	Modal,
	NavTabs,
	GalleryTabs,
	OfferPropertySearch,
	PageHelpful,
	ParametricSearch,
	PasswordChange,
	PasswordReset,
	PersonalGuidance,
	PressLanding,
	PropertyDetail,
	PropertyGrid,
	PropertyToggle,
	ReservationFlyout,
	RoomDetail,
	Search,
	SearchResults,
	SignUp,
	Stays,
	Tablist,
	// TravelLogDetails,
	// TravelLogFlyout,
	UserProfile,
	VacationsInProgress,
	VacationInspiration,
	OfferList,
	OfferSummary,
	WeatherBadge,
};

let core = new Main($, imports, Utils);
core.init();
